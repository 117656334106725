import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";
import { useStaticQuery, graphql } from "gatsby";
// import useDocumentDimensions from "helpers/useDocumentDimensions";
import Header from "components/Header";
import Footer from "components/Footer";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { ScrollSmoother } from "gsap/ScrollSmoother";
import storageAvailable from "helpers/storageAvailable";

gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(ScrollSmoother);
gsap.core.globals("ScrollTrigger", ScrollTrigger);

const CleanLayout = ({ children, color }) => {
  const ref = useRef(null);
  const footerRef = useRef();
  const headerRef = useRef();
  const [showBlogNotification, setShowBlogNotification] = useState(false);
  const {
    allContentfulBlogPost: {
      nodes: [lastPost],
    },
  } = useStaticQuery(GET_LAST_POST);

  useEffect(() => {
    if (!storageAvailable) {
      console.log("clean - storage not available");
      return;
    }

    if (!localStorage.getItem("last-post")) {
      console.log("clean - last-post is not set");
      setShowBlogNotification(true);
      return;
    }

    if (localStorage.getItem("last-post") !== lastPost.id) {
      console.log(
        "clean - last-post is different from last",
        localStorage.getItem("last-post"),
        lastPost.id
      );
      setShowBlogNotification(true);
    }
  }, []);

  useEffect(() => {
    if (headerRef && headerRef.current) {
      const element = headerRef.current;
      const showAnim = gsap
        .from(element, {
          yPercent: -200,
          paused: true,
          duration: 0.2,
        })
        .progress(1);

      gsap.to(element, {
        scrollTrigger: {
          start: "top top",
          end: 99999,
          onUpdate: (self) => {
            self.direction === -1 ? showAnim.play() : showAnim.reverse();
          },
        },
      });
    }
  }, []);

  /* useEffect(() => {
    ScrollSmoother.create({
      smooth: 2, // how long (in seconds) it takes to "catch up" to the native scroll position
      effects: true, // looks for data-speed and data-lag attributes on elements
      smoothTouch: 0.1, // much shorter smoothing time on touch devices (default is NO smoothing on touch devices)
      wrapper: "#___gatsby",
    });
  }); */

  useEffect(() => {
    const element = ref.current;
    const footer = footerRef.current;
    const footerHide = gsap.timeline({
      paused: true,
      scrollTrigger: {
        trigger: element,
        start: "bottom bottom",
        endTrigger: footer,
        end: "bottom bottom",
        scrub: true,
      },
    });
    footerHide.fromTo(
      footer.querySelector("section"),
      {
        yPercent: "-35",
      },
      {
        yPercent: 0,
        ease: "none",
      }
    );
  });

  return (
    // <div className="flex w-screen flex-col mx-auto" ref={ref}>
    <>
      <Header
        ref={headerRef}
        color={color}
        showBlogNotification={showBlogNotification}
        setShowBlogNotification={setShowBlogNotification}
        lastPost={lastPost}
      />
      <main className="flex-1 mx-auto w-screen " ref={ref}>
        {children}
      </main>
      <Footer ref={footerRef} />
    </>
    // </div>
  );
};

const GET_LAST_POST = graphql`
  query getLastPost {
    allContentfulBlogPost(
      sort: { fields: [createdAt], order: DESC }
      limit: 1
    ) {
      nodes {
        id
      }
    }
  }
`;

CleanLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default CleanLayout;
